// import { getResolvedImage } from "@/core/sanityAPI/types";
import { ButtonSchema } from '@/schema';
// import { Button } from "@/uikit/Button";
// import { Button } from "@/uikit/container";
// import { Link } from "@/uikit/Link";
// import { ThemeLink } from "@/uikit/ThemeLink";
import { FlexContainer } from '@/uikit/container';
import React from 'react';
// import { trackEvent } from "@/core/analytics";

export function renderActions(_actions: ButtonSchema[]) {
  return (
    <FlexContainer gap="small" wrap="wrapWithChildGrow">
      {/* {actions?.map(buttonFromSchema)} */}
    </FlexContainer>
  );
}
export function buttonFromSchema(_button: ButtonSchema) {
  // const handleOnClick = () =>
  //   trackEvent("clickButton", {
  //     label: button.trackingEvent || "",
  //     href: button.link?.href || "",
  //   });
  // if (button.variant === "learn-more") {
  //   const isExternal = button.link.href.startsWith("http");
  //   const target = isExternal ? "_blank" : undefined;
  //   return (
  //     <ThemeLink key={button.link.href} to={button.link} target={target} onClick={handleOnClick}>
  //       {button.text}
  //     </ThemeLink>
  //   );
  // }
  // if (button.variant === "link") {
  //   return (
  //     <Link key={button.link.href} to={button.link} onClick={handleOnClick}>
  //       {button.text}
  //     </Link>
  //   );
  // }
  // const iconUrl = button.icon && getResolvedImage(button.icon)?.src;
  // const target = button.link.blank ? "_blank" : undefined;
  // return (
  //   <Button
  //     key={button.link.href}
  //     size={button.size}
  //     variant={button.variant}
  //     fontSize={button.fontSize}
  //     fontWeight={button.fontWeight}
  //     iconIndent={button.iconIndent}
  //     iconPosition={button.iconPosition}
  //     target={target}
  //     to={button.link}
  //     iconUrl={iconUrl}
  //     onClick={handleOnClick}
  //   >
  //     {button.text}
  //   </Button>
  // );
}
