'use client';
import { getResolvedImage } from '@/core/sanityAPI/types';
import type { SanityImageObject } from '@sanity/image-url/lib/types/types';
import Image, { ImageProps } from 'next/image';
import React from 'react';

interface Sizes {
  mobile?: string;
  tablet?: string;
  desktop?: string;
}

const sizeQueries: Sizes = {
  mobile: '(max-width: 768px)',
  tablet: '(max-width: 1200px)',
  desktop: '',
};

interface ApsImageProps {
  priority?: boolean;
  quality?: ImageProps['quality'];
  alt?: string;
  width?: number;
  height?: number;
  className?: string;
  image: SanityImageObject;
  sizes?: Sizes;
  fill?: boolean;
  onClick?: () => void;
  style?: React.CSSProperties;
}

export const parseSizes = (sizes: Sizes) => {
  return Object.keys(sizes)
    .map((key) => {
      return `${sizeQueries[key]} ${sizes[key]}`;
    })
    .join(',');
};

export const RemoteImage = React.memo<ApsImageProps>((props) => {
  const sizes = props.sizes ? parseSizes(props.sizes) : undefined;

  const noFill = !props.fill;

  const resolvedImage = getResolvedImage(props.image);

  const width = props.width || resolvedImage?.width;
  const height = props.height || resolvedImage?.height;

  let styles = noFill
    ? { maxWidth: '100%', width: '100%', height: 'auto' }
    : {};

  return (
    <Image
      src={resolvedImage?.src}
      sizes={sizes}
      width={noFill ? width : undefined}
      height={noFill ? height : undefined}
      priority={props.priority}
      className={props.className}
      style={{ ...styles, ...props.style }}
      alt={props.alt || ''}
      fill={props.fill}
      quality={props.quality}
      onClick={props.onClick}
    />
  );
});

RemoteImage.displayName = 'ApsImage';
