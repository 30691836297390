import { PortableText } from '@portabletext/react';
import React from 'react';
import { SanityBlock, SanityImage } from 'sanity-codegen/types';
import dynamic from 'next/dynamic';
import { RemoteImage } from '@/uikit/remote-image';
import { markers } from './markers.css';

import { theme as defaultTheme } from './default.css';
import { theme as techeryTheme } from './techery.css';
import { theme as blogTheme } from './blog.css';
import { Typography } from '@/uikit/Typography';
import { clsx } from '@/uikit/utils';

import { renderActions } from '../../blocks/schema-mappers';
import { ButtonSchema, SanityKeyed } from '@/schema';

import { widthClass } from '../../blocks/base/config-width.css';
import { alignClass } from '../../blocks/base/config-align.css';

const SyntaxHighlighter = dynamic(
  () => import('react-syntax-highlighter').then((m) => m.Prism),
  { ssr: true },
);

const themes = {
  default: defaultTheme,
  techery: techeryTheme,
  blog: blogTheme,
};

interface RichTextProps {
  body: (
    | SanityBlock
    | SanityImage
    | ButtonSchema
    | SanityKeyed<{ _type: 'imageV2' }>
  )[];
  theme?: keyof typeof themes;
}

const TextBlock = ({ style, children, theme, markDefs }) => {
  const className = theme[style];
  const tag =
    style === 'blockquote' ? 'span' : style === 'normal' ? 'p' : style;

  const prepareSpecs = (child, index) => {
    const marks = child?.marks || [];
    const stylingMarks = marks.map((x) => markers[x]);
    const childClassName = clsx(className, ...stylingMarks);

    const markDef = marks
      .filter((x) => !markers[x])
      .map((x) => markDefs.find((def) => def._key === x));

    // NOTE: special case for inlined links to <p>
    //
    if (markDef.length === 1) {
      const firstOne = markDef[0];
      if (firstOne?._type === 'link') {
        return {
          node: (
            <a key={index} href={firstOne.href} className={theme.a}>
              {child.text}
            </a>
          ),
        };
      } else if (firstOne) {
        throw new Error(`Unknown markDef type ${firstOne?._type}`);
      }
    } else if (markDef.length > 1) {
      console.error('More than one markDef found', markDef);
    }

    // NOTE: special case for empty <p>
    //
    if (style === 'normal' && child.text.length <= 0) {
      return { node: <br key={index} /> };
    }

    return {
      tag: 'span',
      index,
      className: childClassName,
      text: child.text,
    };
  };

  const mappedSpecs = children.map(prepareSpecs);
  if (mappedSpecs.length === 1) {
    const child = mappedSpecs[0];
    return (
      child.node || (
        <Typography settings={{ tag }} className={child.className}>
          {child.text}
        </Typography>
      )
    );
  } else {
    return (
      <Typography settings={{ tag }} className={className}>
        {mappedSpecs.map(
          (child) =>
            child.node || (
              <child.tag key={child.index} className={child.className}>
                {child.text}
              </child.tag>
            ),
        )}
      </Typography>
    );
  }
};

const extractYoutubeId = (url: string) => {
  const match = url.match(
    /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com|youtu\.be)\/(?:watch\?v=)?(.+)/,
  );
  return match ? match[1].split('?')[0] : null;
};

// FIXME: the PortableText is integrated incorrectly
//  should be refactored
//
export const RichText = React.memo(({ body, theme }: RichTextProps) => {
  const cssTheme = themes[theme || 'default'];
  const components = {
    list: (props) => {
      const listType = props.value.listItem;
      if (listType === 'bullet') {
        return <ul className={cssTheme.ul}>{props.children}</ul>;
      } else if (listType === 'number') {
        return <ol className={cssTheme.ol}>{props.children}</ol>;
      } else {
        console.error('Unknown listItem type', listType);
      }
      return <></>;
    },
    listItem: (props) => {
      return <li className={cssTheme.li}>{props.children}</li>;
    },
    // NOTE: review this
    hardBreak: () => <br />,
    // marks: (props) => {
    //   console.log("mark", props);
    //   return <></>;
    // },
    types: {
      // Props = {isInline, index, renderNode, value={actual value}}
      //
      button: (props) => (
        <div className={cssTheme.button}>{renderActions([props.value])}</div>
      ),
      siteLink: ({ value }) => {
        const youtubeId = extractYoutubeId(value.href);
        if (youtubeId) {
          const youtubeUrl = `https://www.youtube.com/embed/${youtubeId}`;
          return (
            <iframe
              className={cssTheme.img}
              width={'100%'}
              style={{ aspectRatio: 1.77 }}
              src={youtubeUrl}
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen
            ></iframe>
          );
        } else {
          return <a href={value.href}>Link</a>;
        }
      },
      // NOTE: image - is for legacy support
      //
      image: ({ value }) => (
        <RemoteImage image={value} className={cssTheme.img} />
      ),
      imageV2: ({ value }) => {
        return (
          <div className={alignClass({ align: value.align })}>
            <RemoteImage
              style={{ height: 'auto' }}
              image={value.content}
              className={clsx(cssTheme.img, widthClass({ width: value.width }))}
            />
          </div>
        );
      },
      block: ({ value }) => {
        const { style, children, markDefs } = value;
        return (
          <TextBlock
            style={style || 'span'}
            theme={cssTheme}
            markDefs={markDefs}
          >
            {children}
          </TextBlock>
        );
      },
      code: ({ value }) => {
        const preTag = (props) => (
          <pre className={cssTheme.blockcode}>{props.children}</pre>
        );
        const codeTag = (props) => (
          <code className={cssTheme.blockcodeInner}>{props.children}</code>
        );
        return (
          <>
            {value.filename?.length > 0 && <em>{value.filename}</em>}
            <SyntaxHighlighter
              key={value.key}
              language={value.language}
              showLineNumbers={false}
              className={cssTheme.blockcode}
              wrapLongLines={true}
              PreTag={preTag}
              CodeTag={codeTag}
            >
              {value.code}
            </SyntaxHighlighter>
          </>
        );
      },
    },
  };
  return (
    <div className={cssTheme.root}>
      <PortableText value={body as any} components={components} />
    </div>
  );
});

RichText.displayName = 'RichText';
