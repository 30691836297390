import { clsx } from '@/uikit/utils';
import React from 'react';
import { flexContainerRecipe, type ContainerRecipe } from './container.css';

export type ContainerProps = {
  className?: string;
  onClick?: () => void;
  onHover?: () => void;
} & React.PropsWithChildren &
  ContainerRecipe;

export const FlexContainer = React.memo((props: ContainerProps) => {
  const { children, className, onClick, onHover, ...rest } = props;
  const containerClassName = clsx(flexContainerRecipe(rest), className);

  return (
    <div
      className={containerClassName}
      onClick={onClick}
      onMouseEnter={onHover}
    >
      {children}
    </div>
  );
});

FlexContainer.displayName = 'FlexContainer';
