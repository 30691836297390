import { TextLinkSchema, NavItemSchema, BlogArticleSchema } from '../schema';
import { SanityDocument } from 'sanity';
import { getResolvedImage } from './sanityAPI/types';
import { useEffect } from 'react';

export const bytesToSize = (bytes: number) => {
  const sizes = ['B', 'KB', 'MB', 'GB', 'TB'];
  const i =
    bytes === 0 ? 0 : Math.floor(Math.log(Math.abs(bytes)) / Math.log(1023));

  if (i === 0) {
    return bytes + ' ' + sizes[i];
  }

  if (i === -1) {
    return bytes.toFixed(2) + ' ' + sizes[0];
  }

  return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
};

export const replaceHttpSrcWithHttps = (html: string) => {
  const pattern = new RegExp(`src="http://`, 'g');
  const replacement = `src="https://`;

  return html.replace(pattern, replacement);
};

export const replaceHttpWithHttps = (link: string) => {
  return link.replace('http://', 'https://');
};

export const getRetinaPath = (path: string) => {
  const components = path.split('.');
  const ext = `.${components.pop()}`;
  return `${path.replace(ext, '')}@2x${ext}`;
};

export function humanizeDuration(duration: number): string {
  const seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor(duration / (1000 * 60 * 60 * 24));
  // weeks = Math.floor(duration / (1000 * 60 * 60 * 24 * 7));

  let result = '';
  // if (weeks > 0) result += `${weeks} week${weeks > 1 ? "s" : ""} `;
  if (days > 0) result += `${days} day${days > 1 ? 's' : ''} `;
  if (hours > 0) result += `${hours} hour${hours > 1 ? 's' : ''} `;
  if (minutes > 0) result += `${minutes} minute${minutes > 1 ? 's' : ''} `;
  if (seconds > 0) result += `${seconds} second${seconds > 1 ? 's' : ''}`;

  return result || '0 seconds';
}

export function isDocumentLike(data: unknown): data is SanityDocument {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  const obj = data as Record<string, unknown>;

  return '_type' in obj;
}

export function isSchemaObject(data: unknown, type: string) {
  if (!isDocumentLike(data)) {
    return false;
  }

  return data._type === type;
}

export function isTextLink(item: unknown): item is TextLinkSchema {
  return isSchemaObject(item, 'textLink');
}

export function isNavItem(item: unknown): item is NavItemSchema {
  return isSchemaObject(item, 'navItem');
}

export function isBlogArticle(item: unknown): item is BlogArticleSchema {
  return isSchemaObject(item, 'blogArticle');
}

export const capitalize = (str: string) =>
  str.charAt(0).toUpperCase() + str.slice(1);

// FIXME: review this, and more to more correct place
//
export const extractOG = (data: any) => {
  const og = data?.meta;

  const title = og?.title;
  const imageUrl = getResolvedImage(og?.seo?.cardImage)?.src;
  const description = og?.seo?.description;

  return {
    title,
    imageUrl,
    description,
  };
};

export const useBackgroundColor = (color: string) => {
  useEffect(() => {
    const originalBackgroundColor = document.body.style.backgroundColor;
    document.body.style.backgroundColor = color;
    return () => {
      document.body.style.backgroundColor = originalBackgroundColor;
    };
  }, []);
};
